import React, { useEffect, useContext } from 'react'
import classNames from 'classnames'
import { graphql } from 'gatsby'
import AccordionContext from 'react-bootstrap/AccordionContext'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle'
import LayoutTransparent from '../components/LayoutTransparent'
import FullHero from '../components/FullHero'
import UpcomingEvent from '../components/UpcomingEvent'

const ns = `purple-garden-cinema-page`

const PurpleGardenCinemaPage = ({ data }) => {
  const { markdownRemark } = data
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  useEffect(() => {
    document.body.style.backgroundColor = '#220026'
    return function cleanup() {
      document.body.style.backgroundColor = '#ffffff'
    }
  }, [])

  function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext)

    const decoratedOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    )

    const isCurrentEventKey = currentEventKey === eventKey

    return (
      <button
        type="button"
        className={[
          'btn btn-link ',
          isCurrentEventKey ? ' opened ' : ' closed ',
        ]}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    )
  }

  return (
    <LayoutTransparent>
      <div className={rootClassnames}>
        <div
          style={{
            backgroundImage: `url("${markdownRemark.frontmatter.background_image.publicURL}")`,
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundAttachment: 'fixed',
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        />
        <FullHero
          desktopImg={markdownRemark.frontmatter.desktop_image.publicURL}
          mobileImg={markdownRemark.frontmatter.mobile_image.publicURL}
        />
        {markdownRemark.frontmatter.upcoming_event_section.show_section && (
          <UpcomingEvent
            mode="light"
            eventTitle={
              markdownRemark.frontmatter.upcoming_event_section.upcoming_event
            }
          />
        )}

        <div className={`${ns}__container`}>
          <h1 className={`${ns}__sub-title sub-title`}>FULL SCHEDULE</h1>
          <div className={`${ns}__content-container`}>
            <div className={`${ns}__sections`}>
              {markdownRemark.frontmatter.schedule.films.map(film => (
                <Accordion>
                  <ContextAwareToggle eventKey="0">
                    <div className="accordian-title">
                      <div className="cinema-details">
                        <p className="date">{film.date}</p>
                        <p className="title">{film.title}</p>
                        <p className="directed-by-title">{film.directed_by}</p>
                      </div>
                      <span className="text-right">
                        <span className="opened-icon">+</span>
                        <span className="closed-icon">—</span>
                      </span>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="accordian-content">
                      <p className="directed-by-content">{film.directed_by}</p>
                      <img src={film.featured_image.publicURL} alt="" />
                      <p>{film.description}</p>
                      <a className="button" href={film.rsvp_link}>
                        RSVP
                      </a>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutTransparent>
  )
}

export default PurpleGardenCinemaPage

PurpleGardenCinemaPage.defaultProps = {
  content: {
    faqs: [
      {
        question: 'What is the story of The Underground Museum?',
        answer: '/',
      },
      {
        question: 'Dan Flavin',
        answer: '/',
      },
    ],
  },
}

export const purpleGardenCinemaPageQuery = graphql`
  query PurpleGardenCinemaPageQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        background_image {
          publicURL
        }
        desktop_image {
          publicURL
        }
        mobile_image {
          publicURL
        }
        logo_alt_text
        upcoming_event_section {
          show_section
          upcoming_event
        }
        schedule {
          films {
            date
            description
            directed_by
            featured_image {
              publicURL
            }
            rsvp_link
            title
          }
        }
      }
    }
  }
`
