import React, { useEffect, useState, Fragment } from 'react'
import classNames from 'classnames'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import FormNewsletter from '../FormNewsletter/FormNewsletter'

const ns = `site-header`

const HeaderTransparent = () => {
  const data = useStaticQuery(graphql`
    query HeaderTransparentQuery {
      menu: markdownRemark(frontmatter: { title: { eq: "Menu" } }) {
        frontmatter {
          menu {
            parent_menu_item
            parent_menu_link
            sub_menu {
              sub_menu_item
              sub_menu_link
            }
          }
          mobile_menu_footer {
            content
            error_message
            newsletter_text
            success_message
            tags_list
          }
        }
      }
      donatebutton: markdownRemark(
        frontmatter: { title: { eq: "Donate Button" } }
      ) {
        frontmatter {
          button_text
          button_url
        }
      }
    }
  `)

  const { menu, donatebutton } = data
  const [isShown, setIsShown] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [path, setPath] = useState()
  const [mobileMenuState, setMobileMenuState] = useState(false)

  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  useEffect(() => {
    document.body.classList.remove('modal-open')
  }, [])

  useEffect(() => {
    const handleStickyHeader = (scrollY, width) => {
      if (scrollY >= 190 && width > 1023) {
        return setIsSticky(true)
      }

      return setIsSticky(false)
    }

    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const screenWidth = document.body.clientWidth
      handleStickyHeader(currentScrollY, screenWidth)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [isSticky])

  const toggleMobileMenu = () => {
    setMobileMenuState(prevMobileMenuState => !prevMobileMenuState)
    document.body.classList.toggle('modal-open')
  }

  useEffect(() => {
    const listenToPopstate = () => {
      const winPath = window.location.hash
      setPath(winPath)
    }

    window.addEventListener('popstate', listenToPopstate, { passive: true })
    return () => {
      window.removeEventListener('popstate', listenToPopstate)
    }
  }, [])

  useEffect(() => {
    setMobileMenuState(false)
    document.body.classList.remove('modal-open')
  }, [path])

  return (
    <div
      className={`${rootClassnames} ${rootClassnames}__transparent ${
        mobileMenuState ? 'active' : ''
      }`}
    >
      <div className={`${ns}__container-logo ${isSticky ? 'sticky' : ''}`}>
        <Link to="/">
          <img className="img-fluid" src="/UM_logo_light.svg" alt="Logo" />
        </Link>
        <div className={`${ns}__donate-button`}>
          <a
            className="button"
            href={donatebutton.frontmatter.button_url}
            target="_blank"
            rel="noreferrer"
          >
            {donatebutton.frontmatter.button_text}
          </a>
        </div>
        <button
          className={`hamburger hamburger--collapse ${
            mobileMenuState ? 'is-active' : ''
          }`}
          type="button"
          onClick={toggleMobileMenu}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <div className={`${ns}__mobile-menu ${mobileMenuState ? 'active' : ''}`}>
        <div className={`${ns}__mobile-menu-container`}>
          {menu.frontmatter.menu.map((item, index) => (
            <Fragment key={`1-${index}`}>
              {item.parent_menu_link.includes('http') ? (
                <h2 key={`2-${index}`} className="title break">
                  <a
                    href={item.parent_menu_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.parent_menu_item}
                  </a>
                </h2>
              ) : (
                <h2 key={`3-${index}`} className="title break">
                  <Link to={item.parent_menu_link}>
                    {item.parent_menu_item}
                  </Link>
                </h2>
              )}
              {item.sub_menu !== null
                ? item.sub_menu.map((subitem, subIndex) => (
                    <a key={`4-${subIndex}`} href={subitem.sub_menu_link}>
                      {subitem.sub_menu_item}
                    </a>
                  ))
                : ''}
            </Fragment>
          ))}
          <div className={`${ns}__mobile-menu--footer`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {menu.frontmatter.mobile_menu_footer.content}
            </ReactMarkdown>
            <div className={`${ns}__mobile-menu--newsletter`}>
              <p>{menu.frontmatter.mobile_menu_footer.newsletter_text}</p>
              <FormNewsletter
                tagList={menu.frontmatter.mobile_menu_footer.tags_list}
                successMessage={
                  menu.frontmatter.mobile_menu_footer.success_message
                }
                errorMessage={menu.frontmatter.mobile_menu_footer.error_message}
              />
            </div>
          </div>
        </div>
      </div>
      <nav
        className={`${ns}__container-menu ${isSticky ? 'sticky' : ''}`}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        {menu.frontmatter.menu.map((item, index) =>
          item.parent_menu_link.includes('http') ? (
            <a
              key={`5-${index}`}
              href={item.parent_menu_link}
              target="_blank"
              rel="noreferrer"
            >
              {item.parent_menu_item}
            </a>
          ) : (
            <Link key={`6-${index}`} to={item.parent_menu_link}>
              {item.parent_menu_item}
            </Link>
          )
        )}

        <div className={`${ns}__container-submenu ${isShown ? 'active' : ''}`}>
          {menu.frontmatter.menu.map((item, index) =>
            item.sub_menu !== null ? (
              <ul key={`7-${index}`}>
                {item.sub_menu.map((subitem, subIndex) => (
                  <li key={`8-${subIndex}`}>
                    <Link key={`9-${subIndex}`} to={subitem.sub_menu_link}>
                      {subitem.sub_menu_item}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <ul key={`10-${index}`} />
            )
          )}
        </div>
      </nav>
    </div>
  )
}

export default HeaderTransparent
