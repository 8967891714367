import React from 'react'
import classNames from 'classnames'
import { useStaticQuery, graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import FormNewsletter from '../FormNewsletter/FormNewsletter'

const ns = `footer`

const Footer = () => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const handleClick = () => {
    window.scrollTo(0, 0)
  }
  const data = useStaticQuery(graphql`
    query FooterTransparentQuery {
      footer: markdownRemark(frontmatter: { title: { eq: "Footer" } }) {
        frontmatter {
          footer_column_one {
            content
            schedule
          }
          footer_column_four {
            content
            tags_list
            success_message
            error_message
          }
          footer_column_three {
            content
          }
          footer_column_two {
            content
          }
          footer_mobile {
            content
            instagram_link
            twitter_link
            vimeo_link
          }
        }
      }
    }
  `)

  const { footer } = data

  return (
    <div className={`${rootClassnames} ${rootClassnames}__transparent`}>
      <div className={`${ns}__container--return-top`}>
        <button type="button" onClick={() => handleClick()}>
          <p>RETURN TO TOP</p>
        </button>
      </div>
      <div className={`${ns}__container container-fluid`}>
        <div className={`${ns}__column-container row`}>
          <div className={`${ns}__column ${ns}__column-laptop col-md-3`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {footer.frontmatter.footer_column_one.content}
            </ReactMarkdown>
            <div className={`${ns}__schedule`}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {footer.frontmatter.footer_column_one.schedule}
              </ReactMarkdown>
            </div>
          </div>
          <div className={`${ns}__column ${ns}__column-mobile col-md-4 col-12`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {footer.frontmatter.footer_mobile.content}
            </ReactMarkdown>
          </div>
          <div className={`${ns}__column ${ns}__column-laptop col-md-3`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {footer.frontmatter.footer_column_two.content}
            </ReactMarkdown>
          </div>
          <div
            className={`${ns}__column ${ns}__column-laptop ${ns}__column--menu col-md-3`}
          >
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {footer.frontmatter.footer_column_three.content}
            </ReactMarkdown>
          </div>
          <div
            className={`${ns}__column ${ns}__column-email col-md-3 col-sm-4 col-12 `}
          >
            <p>{footer.frontmatter.footer_column_four.content}</p>
            <FormNewsletter
              tagList={footer.frontmatter.footer_column_four.tags_list}
              successMessage={
                footer.frontmatter.footer_column_four.success_message
              }
              errorMessage={footer.frontmatter.footer_column_four.error_message}
              mode="light"
            />
          </div>
          <div className={`${ns}__column ${ns}__column-mobile col-md-4 col-12`}>
            <div className="social">
              <a
                href={footer.frontmatter.footer_mobile.instagram_link}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/IG_white.svg" alt="instgram" />
              </a>
              <a
                href={footer.frontmatter.footer_mobile.twitter_link}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/twitter_white.svg" alt="twitter" />
              </a>
              <a
                href={footer.frontmatter.footer_mobile.vimeo_link}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/vimeo_white.svg" alt="vimeo" />
              </a>
            </div>
          </div>
        </div>
        <div className="row justify-content-center d-none d-lg-flex">
          <div className="col-4 ">
            <Link to="/">
              <img className="img-fluid" src="/UM_logo_light.svg" alt="Logo" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
