/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'

import HeaderTransparent from '../HeaderTransparent'
import FooterTransparent from '../FooterTransparent'
import SideLogosTransparent from '../SideLogosTransparent'

const LayoutTransparent = ({ children }) => (
  <>
    <HeaderTransparent />
    <SideLogosTransparent />
    <div>
      <main>{children}</main>
    </div>
    <FooterTransparent />
  </>
)

export default LayoutTransparent
