import React from 'react'
import classNames from 'classnames'

const ns = `full-hero`

const FullHero = ({ desktopImg, mobileImg }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  return (
    <div className={`${rootClassnames}`}>
      <div className={`${ns}__container`}>
        <div className={`${ns}__container-text`}>
          <div className={`${ns}__headline`}>
            <picture>
              <source media="(min-width: 768px)" srcSet={desktopImg} />
              <img src={mobileImg} alt="" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FullHero
